$background-light: #30343d;
$ikizmet-green: #befb06;
$placeholder-blue: #6f7178;
$dashboard-dark-bg: #262931;

// fonts
$font-light: 300;
$font-bold: 700;

// metrics
$radius-25: 25px;

html {
  color: white;
  font-size: 14px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid #fff;
    background-color: #8fc847;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 8px;
  }
}

.logo {
  width: 100%;
  height: auto;
  vertical-align: middle;
}

body {
  background-color: #262931;
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;

  a {
    font-weight: 400;
  }

  a,
  a:hover,
  a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  button:hover {
    cursor: pointer;
  }

  ul {
    list-style: none;
  }
}

#app {
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
  -ms-overflow-x: hidden;
  overflow-x: hidden;
}

.btn-session {
  font-weight: bold;
}

.forgot {
  padding: 10px 20px;
  color: #6f7178;
  cursor: pointer;
}

.signin {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20vh;
  text-align: center;

  .middle {
    display: table-cell;
    vertical-align: middle;
  }

  .inner {
    .mbl {
      display: inline-block;
      width: 178px;
      height: 40px;
      margin: 0 auto;
    }

    .form-inner {
      width: 374px;
      height: 434px;
      margin: 0 auto;
      background-color: $background-light;
      margin-top: 30px;

      &__input {
        margin-bottom: 25px;
      }
      .error {
        height: 20px;
        margin-top: 10px;
        color: #ff2f2f;
        font-weight: bold;
        text-align: left;
      }

      form {
        width: 261px;
        margin: 0 auto;
        padding-top: 120px;
        color: white;
        font-weight: $font-light;

        input {
          margin: 0;

          &::-webkit-input-placeholder,
          &::-moz-placeholder,
          &::-ms-input-placeholder {
            color: $placeholder-blue;
          }
        }

        .btn-session {
          width: 188px;
          height: 43px;
          border-radius: $radius-25;
          color: $background-light;
          border: none;
          background-color: $ikizmet-green;

          &:disabled {
            cursor: not-allowed;
            background-color: $placeholder-blue;
          }
        }

        .session-field {
          width: 100%;
          padding: 10px 16px;
          padding-left: 0px;
          border: 2px solid #ddd;
          transition: all 0.2s ease-out;
          background: none;
          border-top: none;
          border-left: none;
          border-right: none;
          border-bottom: 1px solid #6f7178;
          text-align: center;
          color: white;
          font-weight: 300;

          &:focus {
            outline: none;
          }
        }

        .session-field.email {
          background: url(./images/email_field_icon.gif) no-repeat 0 7px;
        }

        .session-field.password {
          background: url(./images/password_field_icon.gif) no-repeat 0 7px;
        }

        .mtm {
          margin-top: 50px;
          font-weight: $font-bold;

          .option-link {
            position: relative;
            top: 14px;
            font-size: 12px;
            text-align: center;
            display: inherit;
          }
        }

        .forgot-password-link {
          border: none;
          background: none;
          color: #6f7178;
          margin: 0 auto;
          width: 250px;
        }
      }
    }
  }
}

.signin-legal {
  color: #66686f;
  padding: 15px 0 0 0;

  a {
    font-weight: bold;
  }
}

.list {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 0;
  margin: 0;
  overflow: auto;
  justify-content: center;
  flex-direction: column;

  &__item {
    transition: all 0.3s;
    margin-bottom: 10px;

    &:hover {
      transform: scale(1.2);
      opacity: 0.7;
    }
  }
}

.alert__wrapper {
  position: fixed;
  width: 100%;
  height: 48px;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  text-align: center;
  font-size: 17px;
  border: 1px solid transparent;
  border-radius: 0.25rem;

  &-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  &-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  &-secondary {
    color: #41464b;
    background-color: #e2e3e5;
    border-color: #d3d6d8;
  }

  // Injected by react transition group
  &-enter {
    opacity: 0;
  }

  &-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }
}
